import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import AdminNav from './adminNav';
import DeleteIcon from '@mui/icons-material/Delete';
import noData from './no-data.png'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';


export default function MenuOrders() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([])
  const [message, setMessage] = useState('')
  const [id, setId] = useState('')
  const [search, setSearch] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [open, setOpen] = React.useState(false);


  const handleClickOpen = (id) => {
    setOpen(true);
    setDeleteId(id)
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deleteItemById = (id) => {
    axios.post(`/delete_Order`, { id: deleteId })
      .then((result) => {
        getMenuOrders()
        setId(deleteId)
        handleClose()
      })
      .catch(() => {
        setMessage("No item found")
      });
  }
  function getMenuOrders(params) {
    axios
      .post(`get_Order_by_status`, {
        type: '3',
        search: search
      })
      .then((result) => {
        console.log('result2345678', result.data.foundedData);
        setRows(result.data.foundedData.reverse())
        // setMessage("result")
      })
      .catch((err) => {
        setMessage("No Category found")
      });
  }
  const handleChange = (event) => {
    setSearch(event.target.value);
  };
  useEffect(() => {
    getMenuOrders()
  }, [search]);
  return (
    <>
      <AdminNav />
      <Container className='' maxWidth="xl">
      <div className='title-with-search'>
              <h2>Menu Orders</h2>
              <TextField id="outlined-basic" label="Enter name" variant="outlined" value={search}
                onChange={handleChange} />
            </div>
        {rows.length == 0 ?
          <div className='image-no-data-div'>
            <img src={noData} alt="noData image" />

          </div>
          :
          <>
            

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Name</TableCell>
                    <TableCell >Link</TableCell>
                    <TableCell >QR code image </TableCell>
                    {/* qrCodeImage */}
                    <TableCell >Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows && rows.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.fullName}
                      </TableCell>
                      <TableCell>{row.cardLink}</TableCell>
                      <TableCell>{row.qr}</TableCell>
                      <TableCell >
                        <div style={{ display: 'flex' }}>
                          <DeleteIcon onClick={() => handleClickOpen(row._id)} className='cancel-icon' />
                          <EditIcon className='edit-icon' onClick={() => navigate(`/EditOrder?id=${row._id}`)()} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm to delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={() => deleteItemById()} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}